<template>
    <div class="w-full p-4">
        <div id="layout-footer">
            <Button icon="pi pi-comments" label="Have a company you think would be a good fit? Contact left.tech here!" class="p-button-outlined p-button-lg"
                @click="openForm" />
        </div>
    </div>
    <Toast />
    <Dialog header="Send us a message!" v-model:visible="displayForm" :breakpoints="{'960px': '75vw', '640px': '90vw'}"
        :style="{width: '50vw'}">
        <form @submit.prevent="submitForm(!v$.$invalid)" class="p-fluid">
            <div class="field">
                <div class="p-float-label">
                    <InputText id="name" v-model="v$.name.$model" class="input-text" placeholder="Name"
                        :class="{'p-invalid':v$.name.$invalid && submitted}" />
                </div>
                <h2 v-if="(v$.name.$invalid && submitted) || v$.name.$pending.$response" class="p-error">
                    {{v$.name.required.$message.replace('Value', 'Name')}}</h2>
            </div>
            <div class="field">
                <div class="p-float-label p-input-icon-right">
                    <InputText id="email" v-model="v$.email.$model" class="input-text" placeholder="Email"
                        :class="{'p-invalid':v$.email.$invalid && submitted}" aria-describedby="email-error" />
                </div>
                <span v-if="v$.email.$error && submitted">
                    <span id="email-error" v-for="(error, index) of v$.email.$errors" :key="index">
                        <h2 class="p-error">{{error.$message}}</h2>
                    </span>
                </span>
                <h2 v-else-if="(v$.email.$invalid && submitted) || v$.email.$pending.$response" class="p-error">
                    {{v$.email.required.$message.replace('Value', 'Email')}}</h2>
            </div>
            <Textarea v-model="v$.message.$model" :class="{'p-invalid':v$.message.$invalid && submitted}"
                :autoResize="true" rows="10" class="input-text" placeholder="Message" />
            <h2 v-if="(v$.message.$invalid && submitted) || v$.message.$pending.$response"
            class="p-error">{{v$.name.required.$message.replace('Value', 'Message')}}</h2>
            <Button type="submit" label="Submit" class="mt-2" />
        </form>
    </Dialog>
</template>

<script>
import { email, required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { db } from "@/plugins/firebase";
import { collection, addDoc } from "firebase/firestore";

export default {
	setup: () => ({ v$: useVuelidate() }),
	data() {
		return {
			displayForm: false,
			submitted: false,
			name: "",
			email: "",
			message: "",
		}
	},
	validations() {
		return {
			name: {
				required
			},
			email: {
				required,
				email
			},
			message: {
				required
			}
		}
	},
	methods: {
		openForm() {
			this.displayForm = true;
		},
		submitForm(isFormValid) {
			this.submitted = true;
			if (!isFormValid) {
				return;
			}

			addDoc(collection(db, 'mail'), {
				to: ['morgan@grassrootsanalytics.com', 'meghan@grassrootsanalytics.com'],
				from: "dog@treetopintuition.com",
				message: {
					subject: 'Message from left.tech!',
					html: "<h3>You have a message from left.tech</h3> <b>Message from: " + this.name + " &#60;" + this.email + "&#62;</b>" + "<br/> Content: " + this.message
				},
			});
			this.displayForm = false;
			this.$toast.add({severity:'success', summary: 'Success Message', detail:'Message Content', life: 3000});
		}
	}
}

</script>