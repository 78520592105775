import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, setPersistence, browserLocalPersistence } from "firebase/auth";
import { getFirestore }  from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';

const firebaseConfig = {
	apiKey: "AIzaSyDTD1LQExDzLs_d1MkSIcJHGxeNMMOgMaY",
	authDomain: "ga--marketplace.firebaseapp.com",
	projectId: "ga--marketplace",
	storageBucket: "ga--marketplace.appspot.com",
	messagingSenderId: "866683773577",
	appId: "1:866683773577:web:750566422808f591de9c58",
	measurementId: "G-C5VHYKFY1H"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const db = getFirestore();
const functions = getFunctions(app, 'us-east4');

setPersistence(auth, browserLocalPersistence);


export {
	analytics,
	auth,
	db,
	functions
}