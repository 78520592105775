import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import '@/plugins/firebase';
import PrimeVue from 'primevue/config';

import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import ProgressSpinner from 'primevue/progressspinner';
import Card from 'primevue/card';
import Menubar from 'primevue/menubar';
import Toolbar from 'primevue/toolbar';
import FileUpload from 'primevue/fileupload';
import Fieldset from 'primevue/fieldset';
import ToastService from 'primevue/toastservice';
import Toast from 'primevue/toast';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Dialog from 'primevue/dialog';
import Carousel from 'primevue/carousel';
import Textarea from 'primevue/textarea';
import Chip from 'primevue/chip';

import 'primevue/resources/themes/saga-blue/theme.css';
import 'primeicons/primeicons.css';
import 'primevue/resources/primevue.min.css';
import 'primeflex/primeflex.css';
import './assets/theme/globalTheme.css';
import './assets/theme/style.css';

const app = createApp(App);

app.use(PrimeVue);
app.use(ToastService);

app.component('Button', Button);
app.component('InputText', InputText);
app.component('ProgressSpinner', ProgressSpinner);
app.component('Menubar', Menubar);
app.component('Toolbar', Toolbar);
app.component('Card', Card);
app.component('FileUpload', FileUpload);
app.component('Fieldset', Fieldset);
app.component('Toast', Toast);
app.component('DataTable', DataTable);
app.component('Column', Column);
app.component('Dialog', Dialog);
app.component('Carousel', Carousel);
app.component('Textarea', Textarea);
app.component('Chip', Chip);

import {CometChat} from '@cometchat-pro/chat'
import { COMETCHAT_CONSTANTS } from './CONSTS'

var appSetting = new CometChat.AppSettingsBuilder()
	.subscribePresenceForAllUsers()
	.setRegion(COMETCHAT_CONSTANTS.REGION)
	.build();

CometChat.init(COMETCHAT_CONSTANTS.APP_ID, appSetting).then(() => {
	app.use(router).mount("#app");
});


// Delete this and move auth into login. Example here https://www.cometchat.com/tutorials/create-a-chat-app-with-vue
// CometChat.login('superhero2',COMETCHAT_CONSTANTS.AUTH_KEY)