import { createRouter, createWebHistory } from 'vue-router'
// import { auth } from "@/plugins/firebase";
// import { onAuthStateChanged } from "firebase/auth";
// import { CometChatGroupListWithMessages } from "../cometchat-pro-vue-ui-kit/CometChatWorkspace/src";
const routes = [
	// {
	// 	path: '/',
	// 	name: 'Home',
	// 	component: () => import('../components/Auth.vue')
	// },
	// {
	// 	path: '/connect',
	// 	name: 'Chat',
	// 	component: CometChatGroupListWithMessages
	// },
	{
		path: '/',
		name: 'Marketplace',
		component: () => import('../components/Market.vue')
	},
	// {
	// 	path: '/files',
	// 	name: 'Fileshare',
	// 	component: () => import('../components/Fileshare.vue')
	// },
]
const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})

// const isAuthenticated = () => {
// 	return new Promise((resolve, reject) => {
// 		const unsubscribe = onAuthStateChanged(auth, (user) => {
// 			unsubscribe()
// 			resolve(user)
// 		}, reject)
// 	})
// }

// router.beforeEach(async (to) => {
// 	if (!await isAuthenticated() && to.name !== 'Home') { return { name: 'Home'}}
// })
export default router
